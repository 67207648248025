@import "~react-datepicker/dist/react-datepicker.css";
.App header {
  background-color: #282c34;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: white;
  padding: 0.5rem 1rem;
}

ul.menu {
  list-style: none;
}

ul.menu li {
  display: inline;
  padding: 12px;
}

ul.menu a {
  color: #ffffff;
}

.page {
  text-align: center;
}

button {
  background-color: #6200ee;
  color: #ffffff;
  border: none;
  padding: 12px 16px;
  text-transform: uppercase;
  font-weight: bold;
  cursor: pointer;
}

button:hover {
  background-color: #3700b3;
}

.warning {
  width: 800px;
  margin: 24px auto;
  border: 5px solid #ff0000;
  border-radius: 0px;
}

.warning h1 {
  margin-top: 0;
  color: white;
  font-size: 48px;
  line-height: 96px;
  background-color: #ff0000;
  text-transform: uppercase;
}

.warning h2 {
  font-size: 36px;
  text-transform: uppercase;
}

.login-form {
  display: inline-block;
  width: 20em;
}

.full-width-input {
  display: block;
  text-align: right;
}

.full-width-input input {
  width: 12em;
  padding: 4px 8px;
  margin: 4px 8px;
  border: 1px solid #aaaaaa;
  border-radius: 0px;
}

/* Modal Css */
.modal {
  font-size: 12px;
}
.modal > .header {
  width: 100%;
  border-bottom: 1px solid gray;
  font-size: 18px;
  text-align: center;
  padding: 5px;
}
.modal > .content {
  width: 100%;
  padding: 10px 5px;
}
.modal > .actions {
  width: 100%;
  padding: 10px 5px;
  margin: auto;
  text-align: center;
}
.modal > .close {
  cursor: pointer;
  position: absolute;
  display: block;
  padding: 2px 5px;
  line-height: 20px;
  right: -10px;
  top: -10px;
  font-size: 24px;
  background: #ffffff;
  border-radius: 0px;
  border: 1px solid #cfcece;
}

.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: red;
  color: white;
  text-align: center;
}

/* DropDown CSS */
.css-1wa3eu0-placeholder {
  font-size: smaller;
}

.css-yk16xz-control {
  border-color: hsl(0, 0%, 80%);
  border-style: none !important;
}

.css-1okebmr-indicatorSeparator {
  display: none;
}

.css-tj5bde-Svg{
  display: none;
}

.css-1gtu0rj-indicatorContainer{
  display: none;
}

/* Date Picker CSS */
.react-datepicker__navigation--next:hover{
  background-color: #f0f0f0;
}

.react-datepicker__navigation--previous:hover {
  background-color: #f0f0f0;
}

/* header  */
@media only screen and (max-width: 768px) {
  .login-button {
    display: none;
  }
  
  .login-icon {
    display: inline-block;
  }
}

@media only screen and (min-width: 769px) {
  .login-button {
    display: inline-block;
  }
  
  .login-icon {
    display: none;
  }
}

@media only screen and (min-width: 1527px) {
  .login-button {
    margin-left: 0px !important;
    margin-right: -160% !important;
  }
}
